import React from "react"

import * as styles from "./styles/footer.module.scss"
import KamberLogoGrey from "../images/kamber-logo-grey.svg"

const Footer = ({ siteTitle }) => {
  return (
    <footer role="contentinfo">
      <div className={styles.wrap}>
        <div className={styles.footerTopRow}>
          <div className={styles.footerLogos}>
            <KamberLogoGrey />
          </div>
        </div>
        <div className={styles.footerBottomRow}>
          © {new Date().getFullYear()} Kamber, Inc. All rights reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
