import React from "react"
import Layout from "../components/layout"
// import Helmet from "react-helmet"
// import Seo from "../components/seo"

import * as styles from "./styles/index.module.scss"
import KamberLogo from "../images/kamber-logo.svg"
import ScrollIcon from "../images/scroll-icon.svg"
import EscrowTabLogo from "../images/escrowtab-logo-lg.svg"
import LogoDocPrep from "../images/logo-doc-prep.svg"
import LogoEClosing from "../images/logo-eclosing.svg"
import LogoENotes from "../images/logo-enotes.svg"
import LogoEVault from "../images/logo-evault.svg"

const IndexPage = () => {
  return (
    <Layout>      
      <section className={styles.homepageHero}>
        <div className="wrap">
          <div className={styles.heroInner}>
            <header>
              <KamberLogo />
            </header>
            <div className={styles.heroColumns}>
              <div className={styles.heroTitle}>
                <h1>We're a fintech platform with a new angle on digital transactions.</h1>
              </div>
              <div className={styles.heroContent}>
                <h3>Our mission is to deploy productivity tools that bring experience-first solutions with a lower cost to customers.</h3>
                <p><a href="https://escrowtab.com/">See how</a> we're creating process efficiencies in electronic mortgage closings.</p>
              </div>  
            </div>
          </div>
        </div>
        <ScrollIcon className={styles.scrollIcon} />
      </section>
      <section className={styles.ipenSolution}>
        <div className="wrap">
          <header>
            <h2>Introducing the leading IPEN solution.</h2>
            <EscrowTabLogo />
          </header>
          <article>
            <p>And the ecosystem that's forming the new industry landscape.</p>
            <div className={styles.ipenElements}>
              <div><LogoDocPrep /></div>
              <div><LogoENotes /></div>
              <div><LogoEClosing /></div>
              <div><LogoEVault /></div>
            </div>
          </article>
          <footer>
            <a className="button button-primary" href="https://escrowtab.com/">Learn More</a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export function Head() {
  return (
    <>
    <title>Kamber.io · A fintech platform with a new angle on digital transactions</title>
    <script type='application/ld+json'>
      {`{
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Kamber, Inc",
        "url": "https://www.kamber.io",
        "logo": "https://www.kamber.io/images/kamber-logo.png",
        "description": "Kamber is a fintech platform with a new angle on digital transactions. Our mission is to deploy productivity tools that bring experience-first solutions with a lower cost to customers."
      }`}
    </script>
    </>
  )
}