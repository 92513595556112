/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import Helmet from "react-helmet"
 
 import "./styles/layout.css"
 import Footer from "./footer"
 
 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   return (
     <>
       <Helmet>
         <link
           rel="preload"
           href="https://fonts.googleapis.com/css?family=Red+Hat+Display:400,400i,500,500i,700&display=swap"
           as="style"
           onload="this.onload=null;this.rel='stylesheet'"
         />
       </Helmet>
       <main role="main">{children}</main>
       <Footer siteTitle={data.siteMetadata?.title || `Kamber.io`}></Footer>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 